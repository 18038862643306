'use client';

// imports
import { useAtom } from 'jotai';

// lib files
import { StationData } from "@/lib/types/api/stations-data";
import { isPassportMemberOfLocalizedStation } from "@/lib/helpers/is-passport-member";
import { userProfile } from '@/lib/atoms/profile';
import { useHasMounted } from '@/lib/hooks';

// components
import LinkButton from "@/components/Button/LinkButton";

// styles
import styles from './StationHeroDonateButton.module.scss';
interface StationHeroDonateButtonProps {
  stationData: StationData;
}
const StationHeroDonateButton = (props: StationHeroDonateButtonProps) => {
  const {
    stationData
  } = props;
  const [profile] = useAtom(userProfile); // eslint-disable-line react-hooks/rules-of-hooks
  const isLocalizedPassportMember = profile ? isPassportMemberOfLocalizedStation(stationData, profile) : false;
  const stationAttributes = stationData.attributes;
  const shortCommonName = stationAttributes.short_common_name;
  const donateUrl = stationAttributes.donate_url;
  const hasMounted = useHasMounted();
  // Checks if component is mounted on the client before attempting to 
  // hydrate, otherwise a hydration error occurs
  if (!hasMounted) {
    return null;
  }
  return isLocalizedPassportMember ? <LinkButton style="red" href={donateUrl} target="_blank" className={styles.station_hero_donate_button}>
      Make Another Donation to {shortCommonName}
    </LinkButton> : <LinkButton style="light_blue" href={donateUrl} target="_blank" className={styles.station_hero_donate_button}>
      Donate & Become a Member
    </LinkButton>;
};
export default StationHeroDonateButton;