import styles from './LoadingIndicator.module.scss';
interface LoadingIndicatorProps {
  className?: string;
}
const LoadingIndicator: React.FC<LoadingIndicatorProps> = props => {
  const {
    className
  } = props;
  let classNames = styles.loading_indicator;
  if (className) {
    classNames += ` ${className}`;
  }
  return <div className={classNames} data-sentry-component="LoadingIndicator" data-sentry-source-file="LoadingIndicator.tsx">
      <div className={styles.loading_indicator__spinner}>
        <div className={styles.loading_indicator__spinner}></div>
        <div className={styles.loading_indicator__spinner}></div>
        <div className={styles.loading_indicator__spinner}></div>
      </div>
    </div>;
};
export default LoadingIndicator;