'use client';

// imports
import { useAtom } from 'jotai';
import Link from 'next/link';

// lib files
import { userProfile } from '@/lib/atoms/profile';
import { StationData } from '@/lib/types/api/stations-data';
import { useHasMounted } from '@/lib/hooks';

// components
import LinkButton from '@/components/Button/LinkButton';

// styles
import styles from './PassportRowHeader.module.scss';
interface PassportRowHeaderProps {
  stationData: StationData;
  depIsPassportMember?: boolean;
  displayCtas?: boolean;
}
const PassportRowHeader = (props: PassportRowHeaderProps) => {
  const hasMounted = useHasMounted();
  const [profile] = useAtom(userProfile); // eslint-disable-line react-hooks/rules-of-hooks
  // Checks if component is mounted on the client before attempting to
  // hydrate, otherwise a hydration error occurs
  if (!hasMounted) {
    return null;
  }
  const {
    stationData,
    depIsPassportMember = false,
    displayCtas = true
  } = props;
  const {
    attributes: station_info
  } = stationData;
  const {
    short_common_name,
    call_sign,
    passport_url,
    learn_more_passport_url,
    video_portal_url
  } = station_info;
  const isPassportMember =
  // did we pass the depIsPassportMember prop (e.g. from storybook or tests)
  depIsPassportMember ||
  // are they is_passport
  profile?.personal_data?.is_passport;
  let passportLearnMoreUrl = null;
  if (learn_more_passport_url) {
    passportLearnMoreUrl = learn_more_passport_url;
  } else if (video_portal_url) {
    passportLearnMoreUrl = `${video_portal_url}/passport/learn-more/`;
  }
  const donateUrl = passport_url ? passport_url : '/donate';
  if (isPassportMember) {
    return <div className={styles.passport_row_header}>
      <h2 className={styles.passport_row_title}>
        Available with your {short_common_name} Passport benefit
      </h2>
      <p className={styles.passport_row_description}>
        Thank you for being a member! You are supporting America’s largest classroom, the nation’s largest stage for the arts and a trusted window into the world.
      </p>
      <div className={styles.passport_row_ctas}>
        <LinkButton href="/explore/passport/" className={styles.passport_row_explore_button}>
          Explore the Best of Passport
        </LinkButton>
      </div>
    </div>;
  } else {
    return <div className={styles.passport_row_header}>
        <h2 className={styles.passport_row_title}>
          Watch with {short_common_name} Passport
        </h2>
        <p className={styles.passport_row_description}>
          {"Support your local station and get extended access to your favorite PBS shows & films.   "}
          {passportLearnMoreUrl && <Link href={passportLearnMoreUrl} className={styles.passport_row_learn_more_link}>
              What is Passport?
            </Link>}
        </p>
        {displayCtas && <div className={styles.passport_row_ctas}>
            <LinkButton href={donateUrl} style={'light_blue'} className={styles.passport_row_donate_button}>
              Donate & Start Watching
            </LinkButton>
            {passportLearnMoreUrl && <LinkButton href={passportLearnMoreUrl} className={styles.passport_row_learn_more_link_button}>
                What is Passport?
              </LinkButton>}
          </div>}
      </div>;
  }
  ;
};
export default PassportRowHeader;