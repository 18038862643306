import { ProfileData } from "../types/api/profile-data";
import { StationData } from "../types/api/stations-data";

const isPassportMemberOfLocalizedStation = (stationData: StationData, profile: ProfileData): boolean => {
  const { 
    stations: passportMembershipStations, 
    is_passport: hasPassportMembership 
  } = profile.personal_data;
  const localizedCallSign = stationData.attributes.call_sign;
  const isLocalizedToMembershipStation = passportMembershipStations.includes(localizedCallSign);

  return hasPassportMembership && isLocalizedToMembershipStation
}

export { isPassportMemberOfLocalizedStation }